import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Tarif from "./home/Tarif.jsx";
import Soins from "./home/Soins.jsx";
import NotFound from "./home/NotFound.jsx";
import Working from "./home/Working.jsx";
import Mentions from "./home/Mentions.jsx";
import Symptomes from "./home/Symptomes.jsx";
import Guidance from "./home/Guidance.jsx";
import Home from './home/Home.jsx';

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/working" element={<Working/>}></Route>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/Tarifs" element={<Tarif/>}></Route>
        <Route path="/Soins" element={<Soins/>}></Route>
        <Route path="/Symptomes" element={<Symptomes/>}></Route>
        <Route path="/Guidance" element={<Guidance/>}></Route>
        <Route path="/Mentions" element={<Mentions/>}></Route>
        <Route path="*" element={<NotFound/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
