import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from "react-router-dom";

import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import logo_long from './assets/logo_long.png';
import plume from './assets/plume.png';
import scarab from './assets/scarabee.jpg';
import paysage from './assets/pays.jpg';
import './Home.css';
import './Symptomes.css';


export default function Symptomes() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    return (
        <div class="main">
            <div class="head">
                <div>
                    <img alt="sample" src={logo_long} width="100%" />
                </div>
                <div class="nav">
                    <Tabs value={value} variant="scrollable" allowScrollButtonsMobile="true" onChange={handleChange}>
                        <Tab onClick={() => navigate("/")} label="Présentation" />
                        <Tab value={0} onClick={() => navigate("/Symptomes")} label="Les Prestations" />
                        <Tab onClick={() => navigate("/Soins")} label="Déroulement Séance" />
                        <Tab onClick={() => navigate("/Guidance")} label="Guidance" />
                        <Tab onClick={() => navigate("/Tarifs")} label="Tarifs" />
                        <Tab onClick={() => navigate("/Mentions")} label="Mentions légales" />
                    </Tabs>
                </div>
            </div>
            <img alt="logo_page" src={paysage} class="bande" />
            <div class="body">
                <div class="row">
                    <img class="pic" alt="sample" src={plume} width='125%' height='125%' />
                    <div class="column">
                        <h1 class="title">Symptômes traités</h1>
                        <div class="Liste">
                            <div class="Sous-liste">
                                <h3>Les émotions : </h3>
                                <ul>
                                    <li>Colère</li>
                                    <li>Stress</li>
                                    <li>Fatigue</li>
                                    <li>Anxiété</li>
                                    <li>Peur</li>
                                    <li>Tristesse, deuil, séparation</li>
                                </ul>
                            </div>

                            <div class="Sous-liste">
                                <h3>Problèmes de peau :</h3>
                                <ul>
                                    <li>Brûlure</li>
                                    <li>Démangeaison</li>
                                    <li>Eczéma</li>
                                    <li>Verrue</li>
                                    <li>Psoriasis</li>
                                    <li>Cicatrice</li>
                                    <li>Éruption cutanée</li>
                                </ul>
                            </div>

                            <div class="Sous-liste">
                                <h3>Autres :</h3>
                                <ul>
                                    <li>Migraine, maux de têtes</li>
                                    <li>Douleur articulaire</li>
                                    <li>Inflammation</li>
                                    <li>Hémorroïde</li>
                                    <li>Cycle menstruel irrégulier</li>
                                    <li>Trouble du sommeil</li>
                                    <li>Trouble de l'équilibre intestinal</li>
                                    <li>Accompagnement à la grossesse et au désir d'enfant</li>
                                </ul>
                            </div>
                        </div>
                        <h1 class="title">Soin énergétique personnalisé</h1>
                        <div class="text">
                            <p>Le soin énergétique apporte un moment d’apaisement, de bien-être et débloque certains maux,
                                certaines douleurs présents dans le corps de l’autre. Au cours de ces soins, des messages des guides
                                peuvent me parvenir et sont tout naturellement transmis lors des échanges de fin de séance.</p>
                            <p>Ce soin se déroule de la façon suivante :</p>
                            <div class="liste">
                                <ul>
                                    <li>Vérification au pendule de vos énergies</li>
                                    <li>Vérification de vos perturbations énergétiques</li>
                                    <li>Protocole de soin énergétique – scan énergétique</li>
                                    <li>Accompagnement huiles essentielles</li>
                                    <li>Transmission d’énergie positive</li>
                                    <li>Soulagement durant le soin des troubles du quotidien : brulure, inflammation, indigestion,
                                        douleurs internes, maux de têtes, trouble utérin, angoisse, peur, tristesse…</li>
                                    <li>Nettoyage &amp; Harmonisation</li>
                                    <li>Tirage fin de séance</li>
                                </ul>
                            </div>
                            <p>Soin en présentiel ou à distance.</p>
                            <p>Le soin risque de vous chambouler les 10 premiers jours.</p>
                            <p>Lors du soin à distance des échanges ont lieu avant et après la séance.</p>
                        </div>
                        <h1 class="title">Soins du triangle Doré - Soins Isis</h1>
                        <div class="text column">
                            <div class='full'>
                                <p>Le soin énergétique Isis est un soin énergétique personnalisé très puissant sur plusieurs plans issus de l’ancienne Egypte.</p>
                                <p>Il s’adresse aux hommes et aux femmes </p>
                                <p>Il rééquilibre les énergies du féminin et du masculin</p>
                                <p>Harmonise et recharge les chakras en douceur</p>
                                <p>Développe votre intuition et vos ressentis intérieur </p>
                                <p>Agit particulièrement sur le chakra sacré siège de la sexualité, créativité et l’abondance.</p>
                                <p>Soulage les femmes ayant de l’endométriose, ménopause, des douleurs menstruels, des blocages au niveau de la fertilité, des troubles sexuels…</p>
                            </div>
                            <img class="pic-middle" src={scarab} width='15%' height='20%' />
                            <div class='full'>
                                <p>Active une bulle de protection de lumière </p>
                                <p>Permet d’avancer sur le chemin du féminin sacrée </p>
                                <p>Prendre confiance en vous et en votre être intérieur</p>
                            </div>
                        </div>
                        <h1 class="title">Libération des liens d’attachements</h1>
                        <div class="text">
                            <p>Lorsqu’une situation vous semble bloquée entre vous et une personne, ou si vous vous sentez dans
                                une impasse et qu’il est difficile d’avancer, le soin va agir dans le subconscient et va libérer les liens
                                d’attachements ainsi que toutes négativités pouvant vous empêcher d’avancer.</p>
                            <p>Ce soin se déroule de la façon suivante :</p>
                            <div class="liste">
                                <ul>
                                    <li>Vérification au pendule de vos énergies</li>
                                    <li>Protocole par lecture &amp; voyage vers les liens d’attachements</li>
                                    <li>Soin énergétique &amp; ancrage</li>
                                    <li>Anxiété</li>
                                    <li>Protection angélique</li>
                                    <li>Tirage fin de séance</li>
                                </ul>
                            </div>
                            <p>Le soin mettra 21 jours à intégrer vos cellules, vous risquez d’être chamboulé ce qui est normal.</p>
                        </div>

                    </div>
                </div>
            </div>
            <div class="footer">
                <h1>Contactez-moi</h1>
                <div class='info'>
                <h3 class="row"><LocationOnIcon /> <div class="column"><div>50 bis route de Blois</div>  <div>41120 Les Montils</div></div></h3>
                <h3><CallIcon /> 07 65 81 58 40</h3>
                    <h3><MailOutlineIcon /> Soinsenergetiques41@gmail.com</h3>
                </div>
                <div class='info'>
                    <h3><FacebookIcon /><a rel="noreferrer" target="_blank" href="https://www.facebook.com/soinsenergetiques41" underline="none">soinsenergetiques41</a></h3>
                    <h3><InstagramIcon /><a rel="noreferrer" target="_blank" href="https://www.instagram.com/cynthia_energeticienne_spirite/" underline="none">cynthia_energeticienne_spirite</a></h3>
                    <h3><a rel="noreferrer" target="_blank" href="https://www.resalib.fr/praticien/63425-cynthia-beaumenil-energeticien-les-montils" underline="none">RESALIB</a></h3>
                </div>
            </div>
        </div>
    )
}
