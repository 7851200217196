import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from "react-router-dom";

import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import logo_long from './assets/logo_long.png';
import plume from './assets/plume.png';
import paysage from './assets/pays.jpg';
import './Home.css';
import './Soins.css';


export default function Soin() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    return (
        <div class="main">
            <div class="head">
                <div>
                <img alt="sample" src={logo_long} width="100%" />
                </div>
                <div class="nav">
                    <Tabs value={value} variant="scrollable" allowScrollButtonsMobile="true" onChange={handleChange}>
                        <Tab onClick={() => navigate("/")} label="Présentation" />
                        <Tab onClick={() => navigate("/Symptomes")} label="Les Prestations" />
                        <Tab value={0} onClick={() => navigate("/Soins")} label="Déroulement Séance" />
                        <Tab onClick={() => navigate("/Guidance")} label="Guidance" />
                        <Tab onClick={() => navigate("/Tarifs")} label="Tarifs" />
                        <Tab onClick={() => navigate("/Mentions")} label="Mentions légales" />
                    </Tabs>
                </div>
            </div>
            <img alt="logo_page" src={paysage} class="bande" />
            <div class="body">
                <div class="row">
                    <img class="pic" alt="sample" src={plume} />
                    <div class="column">
                        <h1 class="title"> Comment se déroule un soin énergétique ?</h1>
                        <div class="content">
                            <p>Avant la séance, un temps de dialogue est important et nous permet d’échanger sur vos maux.</p>
                            <p>Je recherche ensuite via la radiesthésie les perturbations énergétiques et j'évalue votre taux énergétique de l'instant présent.</p>
                            <p>Lors de la séance vous êtes allongés sur une table, les yeux fermés. Vous devez rester à l’écoute de vos ressentis. Les séances sont accompagnées par des huiles essentielles et par de douces sonorités de guérison qui apaisent le corps et l'esprit.</p>
                            <p>Je me déplace ensuite autour de vous. Je ne fais aucune manipulation.</p>
                            <p>Lorsque la séance est terminée, je pose la main sur votre épaule.</p>
                            <p>Nous échangeons ensuite sur nos ressentis mutuels, émotionnels et physiques. Il faut compter 1 heure de séance.</p>
                        </div>
                        <h1 class="title"> Ressenti Pendant / Après</h1>
                        <div class="content">
                            <p>Chaque personne a ses propres ressentis lors d’une séance. En fonction des maux entre 2 et 4 séances sont nécessaires.</p>
                            <p>La première séance est une prise de contact avec le consultant.</p>
                            <p>La deuxième séance renforce la première et apporte un nouvel équilibre énergétique. Il est conseillé de rapprocher les deux premières séances entre 8 et 10 jours.</p>
                            <p>Les séances suivantes seront déterminées avec le consultant selon ses besoins.</p>
                            <p>Les résultats peuvent se faire ressentir immédiatement, quelques jours après ou quelques semaines. Chaque personne a ses propres ressentis et écoute son propre corps. </p>
                            <p>Les ressentis peuvent être les suivants : picotements, fourmillements, bien être intérieur, apaisement, légèreté...</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <h1>Contactez-moi</h1>
                <div class='info'>
                <h3 class="row"><LocationOnIcon /> <div class="column"><div>50 bis route de Blois</div>  <div>41120 Les Montils</div></div></h3>
                <h3><CallIcon /> 07 65 81 58 40</h3>
                    <h3><MailOutlineIcon /> Soinsenergetiques41@gmail.com</h3>
                </div>
                <div class='info'>
                    <h3><FacebookIcon /><a rel="noreferrer" target="_blank" href="https://www.facebook.com/soinsenergetiques41" underline="none">soinsenergetiques41</a></h3>
                    <h3><InstagramIcon /><a rel="noreferrer" target="_blank" href="https://www.instagram.com/cynthia_energeticienne_spirite/" underline="none">cynthia_energeticienne_spirite</a></h3>
                    <h3><a rel="noreferrer" target="_blank" href="https://www.resalib.fr/praticien/63425-cynthia-beaumenil-energeticien-les-montils" underline="none">RESALIB</a></h3>
                </div>
            </div>
        </div>
    )
}
