import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from "react-router-dom";

import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import logo_long from './assets/logo_long.png';
import plume from './assets/plume.png';
import paysage from './assets/pays.jpg';
import './Home.css';
import './NotFound.css';


export default function NotFound() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    return (
        <div class="main">
            <div class="head">
                <div>
                <img alt="sample" src={logo_long} width="100%" />
                </div>
                <div class="nav">
                    <Tabs value={value} variant="scrollable" allowScrollButtonsMobile="true" onChange={handleChange}>
                        <Tab onClick={() => navigate("/")} label="Présentation" />
                        <Tab onClick={() => navigate("/Symptomes")} label="Les Prestations" />
                        <Tab onClick={() => navigate("/Soins")} label="Déroulement Séance" />
                        <Tab onClick={() => navigate("/Guidance")} label="Guidance" />
                        <Tab onClick={() => navigate("/Tarifs")} label="Tarifs" />
                        <Tab onClick={() => navigate("/Mentions")} label="Mentions légales" />
                    </Tabs>
                </div>
            </div>
            <img alt="logo_page" src={paysage} class="bande" />
            <div class="body">
                <div class="row">
                    <img class="pic" alt="sample" src={plume} width='125%' height='125%' />
                    <div class="column prez">
                        <div class="contact">
                            <h1> Erreur 404 </h1>
                        </div>
                        <p>Je suis désolé, mais la page que vous recherchez n'éxiste pas.</p>
                    </div>
                </div>
            </div>
            <div class="footer">
                <h1>Contactez-moi</h1>
                <div class='info'>
                <h3 class="row"><LocationOnIcon /> <div class="column"><div>50 bis route de Blois</div>  <div>41120 Les Montils</div></div></h3>
                <h3><CallIcon /> 07 65 81 58 40</h3>
                    <h3><MailOutlineIcon /> Soinsenergetiques41@gmail.com</h3>
                </div>
                <div class='info'>
                    <h3><FacebookIcon /><a rel="noreferrer" target="_blank" href="https://www.facebook.com/soinsenergetiques41" underline="none">soinsenergetiques41</a></h3>
                    <h3><InstagramIcon /><a rel="noreferrer" target="_blank" href="https://www.instagram.com/cynthia_energeticienne_spirite/" underline="none">cynthia_energeticienne_spirite</a></h3>
                    <h3><a rel="noreferrer" target="_blank" href="https://www.resalib.fr/praticien/63425-cynthia-beaumenil-energeticien-les-montils" underline="none">RESALIB</a></h3>
                </div>
            </div>
        </div>
    )
}
