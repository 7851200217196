import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import logo_long from './assets/logo_long.png';
import plume from './assets/plume.png';
import paysage from './assets/pays.jpg';
import './Home.css';
import './Working.css';


export default function Working() {

    return (
        <div class="main">
            <div class="head" >
                <div>
                    <img alt="sample" src={logo_long} width="100%" />
                </div>
            </div>
            <img alt="logo_page" src={paysage} class="bande" />
            <div class="body">
                <div class="row">
                    <img class="pic" alt="sample" src={plume} width='125%' height='125%' />
                    <div class="column prez">
                        <div class="contact">
                            <h1>En Construction</h1>
                        </div>
                        <p>Bonjour, le site est actuellement en construction.</p>
                        <h3>Cependant !</h3>
                        <p>Vous pouvez déjà me contacter ici : </p>
                        <div class="column">
                            <h3><MailOutlineIcon /> soinsenergetiques41@gmail.com</h3>
                            <h3><CallIcon /> 06 50 53 37 30</h3>
                            <h3><FacebookIcon /><a rel="noreferrer" target="_blank" href="https://www.facebook.com/soinsenergetiques41" underline="none">soinsenergetiques41</a></h3>
                            <h3><InstagramIcon /><a rel="noreferrer" target="_blank" href="https://www.instagram.com/cynthia_energeticienne_spirite/" underline="none">cynthia_energeticienne_spirite</a></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <h1>Contactez-moi</h1>
                <div class='info'>
                    <h3 class="row"><LocationOnIcon /> <div class="column"><div>11 allée les champs de ligny</div>  <div> Huisseau sur Cosson - 41350</div></div></h3>
                    <h3><CallIcon /> 06 50 53 37 30</h3>
                    <h3><MailOutlineIcon /> Soinsenergetiques41@gmail.com</h3>
                </div>
                <div class='info'>
                    <h3><FacebookIcon /><a rel="noreferrer" target="_blank" href="https://www.facebook.com/soinsenergetiques41" underline="none">soinsenergetiques41</a></h3>
                    <h3><InstagramIcon /><a rel="noreferrer" target="_blank" href="https://www.instagram.com/cynthia_energeticienne_spirite/" underline="none">cynthia_energeticienne_spirite</a></h3>
                    <h3><a rel="noreferrer" target="_blank" href="https://www.resalib.fr/praticien/63425-cynthia-beaumenil-energeticien-les-montils" underline="none">RESALIB</a></h3>
                </div>
            </div>
        </div>
    )
}
