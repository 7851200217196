import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useNavigate } from "react-router-dom";

import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import logo_long from './assets/logo_long.png';
import plume from './assets/plume.png';
import paysage from './assets/pays.jpg';
import './Home.css';
import './Mentions.css';


export default function Mentions() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    return (
        <div class="main">
            <div class="head">
                <div>
                <img alt="sample" src={logo_long} width="100%" />
                </div>
            </div>
            <div class="nav">
                <Tabs value={value} variant="scrollable" allowScrollButtonsMobile="true" onChange={handleChange}>
                    <Tab onClick={() => navigate("/")} label="Présentation" />
                    <Tab onClick={() => navigate("/Symptomes")} label="Les Prestations" />
                    <Tab onClick={() => navigate("/Soins")} label="Déroulement Séance" />
                    <Tab onClick={() => navigate("/Guidance")} label="Guidance" />
                    <Tab onClick={() => navigate("/Tarifs")} label="Tarifs" />
                    <Tab value={0} onClick={() => navigate("/Mentions")} label="Mentions légales" />
                </Tabs>
            </div>
            <img alt="logo_page" src={paysage} class="bande" />
            <div class="body">
                <h1 class="prez">Mentions légales - Soins Energétiques 41</h1>
                <div class="row">
                    <img class="pic" alt="sample" src={plume} width='125%' height='125%' />
                    <div>
                        <h2>Gestion du site : </h2>
                        <div class="bloc">
                            <p>Responsable du site : Cynthia BEAUMENIL</p>
                            <p>soinsenergetiques41@gmail.com</p>
                        </div>
                        <div class="bloc">
                            <p>Créateur du site : Gaël GIROUARD</p>
                            <p>girouard.gael@gmail.com</p>
                        </div>
                        <div class="bloc">
                            <p>Logo du site (plume) : Émilie LAURIER</p>
                            <p>emilie.laurier@ecomail.fr</p>
                        </div>
                        <div class="bloc">
                            <div>
                                <p>Site hébergé par OVHcloud</p>
                                <p> <a href="https://www.ovhcloud.com/">https://www.ovhcloud.com/</a>| 1801 McGill College Ave. ; Suite 800 ; Montreal, Quebec H3A 2N4 ; Canada </p>
                            </div>
                        </div>


                        <h2>Informations du praticien : Cynthia BEAUMENIL</h2>
                        <p>Numéro SIREN : 909060469</p>

                        <h2>Images utilisées : </h2>
                        <p>Bande ciel étoilé : Image par<a href="https://pixabay.com/fr/users/felixmittermeier-4397258/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2750627">Felix-Mittermeier.de</a> de <a href="https://pixabay.com/fr//?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2750627">Pixabay</a></p>
                        <p>Jeux de cartes de Tarot : Image par<a href="https://www.freepik.com/free-vector/hand-drawn-tarot-cards-background_18493571.htm#query=cartomancie&position=3&from_view=search&track=ais">Image by coolvector</a> de Freepik</p>
                        <p>Scarabée Egyptien : Image par<a href="https://www.freepik.com/free-vector/flat-egyptian-symbols-gods-collection_2764432.htm#query=scarabee%20egyptte&position=16&from_view=search&track=ais">Freepik</a></p>
                    </div>
                </div>
            </div>
            <div class="footer">
                <h1>Contactez-moi</h1>
                <div class='info'>
                <h3 class="row"><LocationOnIcon /> <div class="column"><div>50 bis route de Blois</div>  <div>41120 Les Montils</div></div></h3>
                <h3><CallIcon /> 07 65 81 58 40</h3>
                    <h3><MailOutlineIcon /> Soinsenergetiques41@gmail.com</h3>
                </div>
                <div class='info'>
                    <h3><FacebookIcon /><a rel="noreferrer" target="_blank" href="https://www.facebook.com/soinsenergetiques41" underline="none">soinsenergetiques41</a></h3>
                    <h3><InstagramIcon /><a rel="noreferrer" target="_blank" href="https://www.instagram.com/cynthia_energeticienne_spirite/" underline="none">cynthia_energeticienne_spirite</a></h3>
                    <h3><a rel="noreferrer" target="_blank" href="https://www.resalib.fr/praticien/63425-cynthia-beaumenil-energeticien-les-montils" underline="none">RESALIB</a></h3>
                </div>
            </div>
        </div>
    )
}
