import * as React from 'react';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from "react-router-dom";

import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Divider from '@mui/material/Divider';

import paysage from './assets/pays.jpg';
import logo_long from './assets/logo_long.png';
import plume from './assets/plume.png';
import './Home.css';
import './Tarif.css';


export default function Home() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const navigate = useNavigate();

    return (
        <div class="main">
            <div class="head">
                <div>
                <img alt="sample" src={logo_long} width="100%" />
                </div>
                <div class="nav">
                    <Tabs value={value} variant="scrollable" allowScrollButtonsMobile="true" onChange={handleChange}>
                        <Tab onClick={() => navigate("/")} label="Présentation" />
                        <Tab onClick={() => navigate("/Symptomes")} label="Les Prestations" />
                        <Tab onClick={() => navigate("/Soins")} label="Déroulement Séance" />
                        <Tab onClick={() => navigate("/Guidance")} label="Guidance" />
                        <Tab value={0} onClick={() => navigate("/Tarifs")} label="Tarifs" />
                        <Tab onClick={() => navigate("/Mentions")} label="Mentions légales" />
                    </Tabs>
                </div>
            </div>
            <img alt="logo_page" src={paysage} class="bande" />
            <div class="body">
                
                <div class="row">
                    <img class="pic" alt="sample" src={plume} width='100%' />
                    <div class="tarifs">
                        <h1>Tarifs des Prestations</h1>
                        <Divider/>
                        <h3>Cartomancie guidance</h3>
                        <h4>1h : 60€ </h4>
                        <h4>30min : 30€</h4>
                        <h3 class='av_detail'>Soins Personnalisés - Magnétisme</h3>
                        <p>(Vue d'ensemble, libération de blocage de vie - d'âme - rejet - abandon - humiliation - trahison - injustice - lien d'attachement)</p>
                        <h4>Sur photo ou présentiel</h4>
                        <h4>Adulte : 60€ </h4>
                        <h4>Enfant (- 10 ans) : 40€</h4>
                        <h3>Animaux : 40€</h3>
                        <h3>Soins du triangle Doré - Soins Isis</h3>
                        <h4>1h : 60€</h4>
                        <h3>Soin un jour une huille essentielle</h3>
                        <h4>60€</h4>
                        <h3>Massage bien-être - réflexologie</h3>
                        <h4>60€</h4>
                        <h3 class='av_detail'>Problèmes de peau hors soin - 40€</h3>
                        <p>(Verrue, eczema, zona, brûlure, démangeaison, ...)</p>
                        <span class="desc">
                            <br />
                            */Les séances durent 1h avec un temps d'échange avant et après le soin. <br />
                            */Chaque soin est accompagné de douces sonorités. <br />
                            */Le paiement pour les soins à distance doit être effectué 48h avant par virement bancaire. <br />
                            */Séance possible à distance, sur photo, en visio ou en présentiel au cabinet                           
                        </span>
                    </div>
                </div>
            </div>
            <div class="footer">
                <h1>Contactez-moi</h1>
                <div class='info'>
                <h3 class="row"><LocationOnIcon /> <div class="column"><div>50 bis route de Blois</div>  <div>41120 Les Montils</div></div></h3>
                    <h3><CallIcon /> 07 65 81 58 40</h3>
                    <h3><MailOutlineIcon /> Soinsenergetiques41@gmail.com</h3>
                </div>
                <div class='info'>
                    <h3><FacebookIcon /><a rel="noreferrer" target="_blank" href="https://www.facebook.com/soinsenergetiques41" underline="none">soinsenergetiques41</a></h3>
                    <h3><InstagramIcon /><a rel="noreferrer" target="_blank" href="https://www.instagram.com/cynthia_energeticienne_spirite/" underline="none">cynthia_energeticienne_spirite</a></h3>
                    <h3><a rel="noreferrer" target="_blank" href="https://www.resalib.fr/praticien/63425-cynthia-beaumenil-energeticien-les-montils" underline="none">RESALIB</a></h3>
                </div>
            </div>
        </div>
    )
}
